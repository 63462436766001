import React from 'react';
import { Link } from 'gatsby';
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaSpotify,
  FaApple,
} from 'react-icons/fa';

import Logo from 'assets/white-glow.png';

import {
  Container,
  Wrap,
  TextWrap,
  PagesWrap,
  LogoWrap,
  PagesList,
  Socials,
  Copyright,
} from './styled';

const Footer = () => {
  return (
    <Container>
      <Wrap>
        <LogoWrap>
          <Link to='/'>
            <img src={Logo} alt='The Glow Logo' />
          </Link>
        </LogoWrap>
        <TextWrap>
          <PagesWrap>
            <PagesList>
              <li>
                <Link to='/new-reccs'>New Reccs</Link>
              </li>
              <li>
                <Link to='/explore'>Explore</Link>
              </li>
              <li>
                <Link to='/selector'>Selector</Link>
              </li>
              <li>
                <Link to='/features'>Features</Link>
              </li>
            </PagesList>
            <PagesList>
              <li>
                <Link to='/about'>About Us</Link>
              </li>
              <li>
                <Link to='/ad'>Advertise</Link>
              </li>
              <li>
                <a
                  href='https://www.patreon.com/theglowjp'
                  target='_blank'
                  rel='noreferrer'
                >
                  Support Us
                </a>
              </li>
              <li>
                <Link to='/privacy-policy'>Privacy Policy</Link>
              </li>
            </PagesList>
          </PagesWrap>
          <Copyright>
            <Socials>
              <ul>
                <li>
                  <a
                    href='https://www.instagram.com/theglow.jp/'
                    target='_blank'
                    rel='noreferrer'
                    role='button'
                    aria-label='Mute volume'
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.facebook.com/theglow.jp'
                    target='_blank'
                    rel='noreferrer'
                    role='button'
                    aria-label='Mute volume'
                  >
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a
                    href='https://twitter.com/theglow_jp/'
                    target='_blank'
                    rel='noreferrer'
                    role='button'
                    aria-label='Mute volume'
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a
                    className='socialMobileItem'
                    href='https://open.spotify.com/user/ji21784pk94jfyv4u9w01xkhe?si=ZOykmTClStyjbTpG9XmPGg'
                    target='_blank'
                    rel='noreferrer'
                    role='button'
                    aria-label='Mute volume'
                  >
                    <FaSpotify />
                  </a>
                </li>
                <li>
                  <a
                    className='socialMobileItem'
                    href='https://itunes.apple.com/profile/theglow_jp'
                    target='_blank'
                    rel='noreferrer'
                    role='button'
                    aria-label='Mute volume'
                  >
                    <FaApple />
                  </a>
                </li>
              </ul>
            </Socials>
            <p>© 2020 The Glow JP Ltd. All rights reserved. </p>
          </Copyright>
        </TextWrap>
      </Wrap>
    </Container>
  );
};

export default Footer;
