import React from 'react';
import { Link } from 'gatsby';

import { ScrollAlbumContainer, ScrollAlbumDetails } from './styled';

const ScrollAlbum = ({ node }) => {
  const { slug, artwork, genre, artists, englishTitle, releaseDate } = node;

  return (
    <ScrollAlbumContainer>
      <Link to={`/reccs/${slug}`}>
        <img src={artwork.url} alt='' />
      </Link>
      <ScrollAlbumDetails>
        <p class='genre'>{genre.name}</p>
        <Link to={`/reccs/${slug}`}>
          <p class='artist'>{artists[0].englishName}</p>
        </Link>
        <Link to={`/reccs/${slug}`}>
          <p class='album'>{englishTitle}</p>
        </Link>
        <p class='date'>{releaseDate}</p>
      </ScrollAlbumDetails>
    </ScrollAlbumContainer>
  );
};

export default ScrollAlbum;
