import React from 'react';
import { Link } from 'gatsby';

import ScrollAlbum from './ScrollAlbum';
import {
  Container,
  Content,
  LatestAlbum,
  LatestAlbumWrap,
  LatestAlbumDetails,
  ScrollAlbums,
  SectionTitle,
} from './styled';

const Albums = ({ edges }) => {
  if (!Array.isArray(edges)) return null;

  if (edges.length < 1) {
    return (
      <>
        <p>Content is on the way - stay tuned!</p>
      </>
    );
  }

  const latestAlbum = edges[0].node;
  const scrollAlbums = edges.slice(1, 14);

  return (
    <Container>
      <Content>
        <LatestAlbum>
          <LatestAlbumWrap>
            <Link to={`/reccs/${latestAlbum.slug}`}>
              <img src={latestAlbum.artwork.url} alt='' />
            </Link>
            <LatestAlbumDetails>
              <Link to={`/reccs/${latestAlbum.slug}`}>
                <p class='artist'>{latestAlbum.artists[0].englishName}</p>
              </Link>
              <Link to={`/reccs/${latestAlbum.slug}`}>
                <p class='album'>{latestAlbum.englishTitle}</p>
              </Link>
              <p class='date'>{latestAlbum.releaseDate}</p>
            </LatestAlbumDetails>
          </LatestAlbumWrap>
        </LatestAlbum>
        <ScrollAlbums>
          {scrollAlbums.map((edge) => {
            return <ScrollAlbum {...edge} />;
          })}
        </ScrollAlbums>
        <SectionTitle>
          <Link to='/new-reccs'>
            <h2>New Reccs</h2>
          </Link>
        </SectionTitle>
      </Content>
    </Container>
  );
};

export default Albums;
