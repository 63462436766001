import React from 'react';
import { Link } from 'gatsby';

export const formatAuthor = (authors) => {
  if (authors.length > 1) {
    return `${authors[0].englishName} & ${authors[1].englishName}`;
  } else if (authors.length > 0) {
    return authors[0].englishName;
  } else {
    return 'The Glow';
  }
};

export const formatTags = (type, array) => {
  let artistTags = [];

  if (!Array.isArray(array)) {
    return <Link to={`/${type}/${array.slug}`}>{array.englishName}</Link>;
  }

  for (let i = 0; i < array.length; i++) {
    if (i === array.length - 1) {
      artistTags.push(
        <Link to={`/${type}/${array[i].slug}`}>{array[i].englishName}</Link>
      );
    } else {
      artistTags.push(
        <Link to={`/${type}/${array[i].slug}`}>{array[i].englishName}, </Link>
      );
    }
  }

  return artistTags;
};
