import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

const SEO = ({ title, description, image, article, isBlogPost }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    defaultDescription,
    defaultUrl,
    defaultImage,
    defaultLang,
    titleTemplate,
    twitterUsername,
  } = site.siteMetadata;

  const formatedUrl = defaultUrl.endsWith('/')
    ? defaultUrl.substring(0, defaultUrl.length - 1)
    : defaultUrl;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || `${defaultUrl}${defaultImage}`,
    url: `${formatedUrl}${pathname}`,
    lang: defaultLang,
  };

  return (
    <Helmet
      title={title ? seo.title : defaultDescription}
      titleTemplate={titleTemplate}
      htmlAttributes={{
        lang: seo.lang,
      }}
    >
      {/* General tags */}
      <html lang={seo.lang} />
      <meta name='description' content={seo.description} />
      <meta name='image' content={seo.image} />
      <link rel='canonical' href={formatedUrl + withPrefix(pathname)} />

      {/* OpenGraph tags */}
      {seo.url && <meta property='og:url' content={seo.url} />}
      {(article ? true : null) && (
        <meta property='og:type' content={isBlogPost ? 'article' : 'website'} />
      )}
      {seo.title && <meta property='og:title' content={seo.title} />}
      {seo.description && (
        <meta property='og:description' content={seo.description} />
      )}
      {seo.image && <meta property='og:image' content={seo.image} />}

      {/* Twitter Card tags */}
      <meta name='twitter:card' content='summary_large_image' />
      {twitterUsername && (
        <meta name='twitter:creator' content={twitterUsername} />
      )}
      {seo.title && <meta name='twitter:title' content={seo.title} />}
      {seo.description && (
        <meta name='twitter:description' content={seo.description} />
      )}
      {seo.image && <meta name='twitter:image' content={seo.image} />}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultUrl: url
        defaultImage: image
        defaulyLang: lang
        titleTemplate
        twitterUsername
        facebookUsername
        instagramUsername
      }
    }
  }
`;

export default SEO;
