import styled from 'styled-components';

import { FlexWrap } from 'components/common/Containers/styled';

export const GuidesFlexWrap = styled(FlexWrap)`
  margin: 0 -15px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0;
  }
`;

export const GuideContainer = styled.div`
  position: relative;
  flex-basis: calc(33.3333% - 30px);
  margin: 0 15px 50px;
  transition: transform 450ms;
  &:hover {
    transform: scale(1.08);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    flex-basis: calc(50% - 30px);
    margin: 0 15px 50px;
    transition: none;
    &:hover {
      transform: none;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-basis: 100%;
    margin: 0 0 50px;
    &:nth-of-type(5) {
      display: ${(props) => (props.isPreview ? 'none' : 'block')};
    }
    &:nth-of-type(6) {
      display: ${(props) => (props.isPreview ? 'none' : 'block')};
    }
  }
`;

export const GuideWrap = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
`;

export const Image = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
  color: #fff;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 15px;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    height: 160px;
  }
`;

export const Title = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  p {
    color: white;
    text-transform: uppercase;
    font-size: 18px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    transform: translateY(-70%);
  }
`;
