import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1000;
  padding: 100px 0 80px;
  background-color: black !important;
  color: rgb(153, 153, 153);
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 40px 0 120px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  width: 72%;
  max-width: 1080px;
  padding: 0 1rem;
  transform: translateX(80px);
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    transform: none;
  }
`;

export const LogoWrap = styled.div`
  margin-top: 4px;
  font-size: 1rem;
  transform: translateY(-6px);
  img {
    height: 70px;
    margin: 0 0 40px;
    &:hover {
      filter: brightness(0) invert(1) !important;
    }
  }
`;

export const TextWrap = styled.div`
  width: 350px;
  float: right;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 90%;
    margin: 0 auto;
    float: revert;
  }
`;

export const PagesWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 200px;
  a {
    color: rgb(153, 153, 153);
    &:hover {
      color: white;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: 20px;
    width: 80%;
    margin: 0 auto 20px;
  }
`;

export const PagesList = styled.ul`
  position: relative;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin-bottom: 24px;
  }
`;

export const Socials = styled.div`
  padding-bottom: 12px;
  ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      margin-right: 18px;
      padding: 12px 13px 9px 13px;
      color: rgb(153, 153, 153);
      font-size: 24px;
      text-decoration: none;
      border: 1px solid rgb(153, 153, 153);
      border-radius: 50%;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: rgb(153, 153, 153);
      }
      &:hover {
        cursor: pointer;
        a {
          color: white;
        }
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    ul {
      flex-wrap: wrap;
      text-align: center;
      width: 65%;
      margin: 0 auto;
      justify-content: center;
      li {
        margin: 0 10px 10px;
      }
    }
  }
`;

export const Copyright = styled.div`
  width: 100%;
  padding: 4px 0;
  text-align: center;
  font-size: 12px;
  p {
    float: left;
  }
  a {
    color: rgb(153, 153, 153);
    &:hover {
      color: white;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0;
    p {
      float: revert;
    }
  }
`;
