import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100vw - 160px);
  position: relative;
  padding: 0;
  margin: 0 auto;
  border-bottom: 1px solid;
  border-color: ${(props) => props.borderColor || '#000000'};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const List = styled.div`
  display: flex;
  max-width: 960px;
  list-style-type: none;
  padding: 26px 0;
`;

export const ListItem = styled.div`
  margin-right: 45px;
  &:last-of-type {
    margin-right: 0;
  }
  a {
    color: ${(props) => props.textColor || '#000000'};
    font-size: 1.6rem;
    text-decoration: none;

    &:hover {
      color: #7454a1;
      transition: color 200ms;
    }
  }
`;
