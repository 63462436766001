import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { formatTags } from 'helpers/formatLists';

import { Container, Subtitle, Body, ArtistTags } from './styled';

const TextBody = ({ body, subtitle, artists, authors }) => {
  const artistTags = artists ? formatTags('artist', artists) : null;
  const authorTags = authors ? formatTags('author', authors) : null;

  return (
    <Container>
      <Subtitle>
        <ReactMarkdown
          children={subtitle}
          linkTarget='_blank'
          rehypePlugins={[rehypeRaw]}
          transformImageUri={(uri) =>
            uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`
          }
        />
      </Subtitle>
      <Body>
        <ReactMarkdown
          children={body}
          linkTarget='_blank'
          rehypePlugins={[rehypeRaw]}
          // transformImageUri={(uri) =>
          //   uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`
          // }
        />
      </Body>
      {(artistTags || authorTags) && (
        <ArtistTags>
          {authorTags && <p>Author: {authorTags}</p>}
          {artistTags && <p>Artist Tags: {artistTags}</p>}
        </ArtistTags>
      )}
    </Container>
  );
};

export default TextBody;
