import styled from 'styled-components';

import { FlexWrap } from 'components/common/Containers/styled';

export const SelectorFlexWrap = styled(FlexWrap)`
  margin: 0 -27.5px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
  }
`;

export const SelectorContainer = styled.div`
  min-height: 100px;
  transition: transform 450ms;
  margin: 0 27.5px 40px;
  &:hover {
    transform: scale(1.08);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 0 40px;
    flex-basis: 140px;
    width: 140px;
    &:last-of-type {
      display: none;
    }
    &:hover {
      transform: none;
    }
  }
`;

export const ImgWrap = styled.div`
  img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    object-fit: cover;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    img {
      width: 100%;
      height: 140px;
    }
  }
`;

export const Details = styled.div`
  text-align: center;
  width: 180px;
  margin: 20px auto 0;
  p {
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    p {
      line-height: 1;
    }
  }
`;
