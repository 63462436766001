import styled from 'styled-components';

export const AlbumsContainer = styled.div`
  position: relative;
  margin: 0 auto;
  h4 {
    text-transform: capitalize;
    padding-left: 40px;
    margin-bottom: 17px;
    font-size: 26px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    h4 {
      padding-left: 0;
    }
  }
`;

export const AlbumsWrap = styled.div`
  display: flex;
  overflow-x: hidden;
  padding-top: 20px;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  transform: translateX(5px);
`;

export const Carousel = styled.div`
  display: flex;
  transition: transform 0.4s ease-in-out;
  &::-webkit-scrollbar {
    visibility: hidden;
  }
  &:first-child {
    padding-left: 0px;
  }
`;

export const ViewAll = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 40px;
  transform: translateY(-7.7px);
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: 0;
  }
`;

export const Button = styled.button`
  z-index: 100;
  position: absolute;
  top: 0;
  font-size: 16px;
  color: white;
  letter-spacing: 1px;
  border: 1px solid white !important;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 11px 14px;
  line-height: 1.25;
  letter-spacing: 1px;
  border: 0;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s ease 0s;
  ${AlbumsContainer}:hover & {
    opacity: 1;
  }
  svg {
    transform: translate(0px, 2px);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 1) !important;
  }
`;

export const NextButton = styled(Button)`
  right: 0;
  transform: translate(-40px, 130px);
`;

export const PrevButton = styled(Button)`
  display: none;
  left: 0;
  transform: translate(60px, 130px);
`;
