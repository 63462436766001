import styled from 'styled-components';

export const InnerWrap = styled.div`
  width: 1220px;
  max-width: 90%;
  margin-left: 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    width: 970px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 auto;
  }
`;

export const InnerWrapCenter = styled.div`
  width: 1220px;
  max-width: 90%;
  margin: 0 auto;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    width: 970px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 auto;
  }s
`;

export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  a {
    transform: translateY(24px);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 30px;
  }
`;

export const MobilePageHeader = styled.div`
  display: none;
  background-color: black;
  h1 {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
    color: white;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: block;
  }
`;

export const MobileLatestInterview = styled.div`
  display: none;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 30px;
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: block;
  }
`;

export const MobileLatestAlbums = styled.div`
  display: none;
  max-width: 90%;
  margin: -90px auto 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: block;
  }
`;
