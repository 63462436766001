import styled from 'styled-components';

import { FlexWrap } from 'components/common/Containers/styled';

export const FeatureFlexWrap = styled(FlexWrap)`
  margin: 0 -15px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 -10px;
  }
`;

export const FeatureContainer = styled.div`
  position: relative;
  flex-basis: calc(33.3333% - 30px);
  margin: 0 15px 50px;
  transition: transform 450ms;
  &:hover {
    transform: scale(1.08);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    flex-basis: calc(50% - 30px);
    margin: 0 15px 50px;
    transition: none;
    &:hover {
      transform: none;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-basis: 100%;
    margin: 0 0 50px;
  }
`;

export const Category = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  margin-right: 28px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
`;

export const ImgWrap = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 15px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 0.4s ease-in-out;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    height: 160px;
  }
`;

export const TextWrap = styled.div`
  h3 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.2;
    transform: none;
  }
`;

export const Info = styled.div`
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  margin-top: 5px;
  span {
    margin: 0 8px;
  }
`;
