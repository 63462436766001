import React from 'react';

import SelectorCard from './Card';
import { SelectorFlexWrap } from './styled';

const SelectorsLatest = ({ edges }) => {
  return (
    <SelectorFlexWrap>
      {edges.map((edge, pos) => {
        return <SelectorCard {...edge.node} key={pos} />;
      })}
    </SelectorFlexWrap>
  );
};

export default SelectorsLatest;
