import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 60px;
  height: 100%;
  z-index: 999;
  box-sizing: border-box;
  border-right: 1px solid white;
  background-color: transparent;
  transition: background-color 1s ease 0s;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  h1 {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    width: 200px;
    font-size: 30px;
    font-weight: normal;
    a {
      color: white;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const BackgroundColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const Hamburger = styled.div`
  color: white;
`;
