import React from 'react';
import { Link } from 'gatsby';

import { SelectorContainer, ImgWrap, Details } from './styled';

const SelectorCard = ({ artists, coverImage, slug }) => {
  return (
    <SelectorContainer>
      <Link to={`/selector/${slug}`}>
        <ImgWrap>
          <img src={coverImage.url} alt={artists[0].englishName} />
        </ImgWrap>
      </Link>

      <Details>{artists && <p>{artists[0].englishName}</p>}</Details>
    </SelectorContainer>
  );
};

export default SelectorCard;
