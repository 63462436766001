import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { LatestContainer, LatestImg, Hashtag, Id } from './styled';

const Selectors = ({ node }) => {
  const { artists, coverImage, selectorID, slug } = node;

  return (
    <LatestContainer>
      <LatestImg>
        <Link to={`${slug}`}>
          {coverImage && <img src={coverImage.url} alt='' />}
          <h2>
            <span>#{selectorID}</span>
            {artists[0].englishName}
          </h2>
        </Link>
      </LatestImg>
      <Hashtag>
        <p>
          <strong>#</strong>
        </p>
      </Hashtag>
      <Id>
        <p>
          <strong>{selectorID}</strong>
        </p>
      </Id>
    </LatestContainer>
  );
};

export default Selectors;
