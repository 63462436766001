import styled from 'styled-components';

export const Container = styled.div`
  margin: 40px 0 60px -76px;
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  align-items: stretch;
  width: 1430px;
  margin: 0 auto;
  padding: 0 0 0 122px;
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: none;
  }
`;

export const LatestAlbum = styled.div`
  width: 440px;
  min-width: 370px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
  max-height: 100vh;
  margin: 100vh 30px -97.6vh 0;
  transform: translateY(-80vh);
  img {
    height: auto;
    width: 100%;
    position: relative;
    border-radius: 15px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    min-width: auto;
    margin: 0;
    top: revert;
    transform: none;
    height: auto;
  }
`;

export const LatestAlbumWrap = styled.div`
  width: 90%;
  margin: 50px auto 0;
  img {
    width: 100%;
    height: auto;
  }
`;

export const ScrollAlbums = styled.div`
  width: 460px;
  padding-left: 0;
  margin-top: 60px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const ScrollAlbumContainer = styled.div`
  display: flex;
  height: 170px;
  width: 100%;
  margin-bottom: 30px;
  transition: transform 450ms;
  &:hover {
    transform: scale(1.08);
  }
  img {
    height: 100%;
    width: 170px;
    object-fit: cover;
    border-radius: 15px;
  }
`;

const Details = styled.div`
  p {
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: black;
  }
  .genre {
    text-transform: uppercase;
    margin-bottom: 0.7rem;
    font-family: 'Elevon';
    font-size: 12px;
    color: #7454a1;
  }
  .artist {
    font-size: 16px;
    font-weight: bolder;
  }
  .album {
    font-size: 16px;
    font-weight: lighter;
    font-style: italic;
  }
  .date {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bolder;
    margin-top: 0.7rem;
    margin-bottom: 0;
    color: silver;
  }
`;

export const ScrollAlbumDetails = styled(Details)`
  position: relative;
  margin: auto 0;
  width: 60%;
  padding-left: 30px;
`;

export const LatestAlbumDetails = styled(Details)`
  text-align: center;
  margin-top: 2rem;
`;

export const SectionTitle = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
  max-height: 100vh;
  width: 355px;
  margin: 100vh 30px -97.6vh 0;
  transform: translateY(-75vh);

  h2 {
    transform: rotate(90deg);
    font-size: 100px;
    margin: 0;
    padding: 0;
    color: transparent;
    -webkit-text-stroke: 2px black;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tabletLan}) {
    display: none;
  }
`;
