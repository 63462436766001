import styled from 'styled-components';

import { FlexWrap } from 'components/common/Containers/styled';

export const AlbumFlexWrap = styled(FlexWrap)`
  margin: 0 -27.5px;
  @media (max-width: ${(props) => props.theme.breakpoints.tabletLan}) {
    margin: 0 -20px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    margin: 0 -10px;
  }
`;

export const AlbumContainer = styled.div`
  min-height: 100px;
  transition: transform 450ms;
  margin: 0 27.5px 40px;
  &:hover {
    transform: scale(1.08);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletLan}) {
    margin: 0 15px 40px;
    width: calc(33.33% - 30px);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    margin: 0 0 40px;
    transition: none;
    min-height: auto;
    width: calc(50% - 10px);
    &:hover {
      transform: none;
    }
    &:nth-child(odd) {
      margin-right: 10px;
    }
    &:nth-child(even) {
      margin-left: 10px;
    }
  }
`;

export const ImgWrap = styled.div`
  img {
    height: 200px;
    width: 200px;
    border-radius: 15px;
    object-fit: cover;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    width: 100%;
    img {
      height: auto;
      width: 100%;
    }
  }
`;

export const Details = styled.div`
  text-align: center;
  width: 180px;
  margin: 0 auto;
  p {
    font-size: 16px;
    font-weight: bolder;
    padding: 0;
    margin: 0;

    &:first-of-type {
      margin-top: 15px;
    }
    &:nth-of-type(2) {
      font-weight: lighter;
      font-style: italic;
    }
    &:last-of-type {
      font-size: 10px;
      margin: 7px 0 0;
      text-transform: uppercase;
      color: silver;
    }
  }
`;
