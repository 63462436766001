import React from 'react';
import { Link } from 'gatsby';
import { FaSpotify, FaApple } from 'react-icons/fa';

import {
  Container,
  AlbumWrap,
  ArtistWrap,
  ArtistName,
  StreamingList,
  StreamingItem,
  TextWrap,
  ImgWrap,
  Info,
  MobileLabelInfo,
} from './styled';

const Banner = ({
  bannerBackgroundColor,
  bannerTextColor,
  image,
  title,
  bio,
  artists,
  date,
  label,
  foreginCollaborator,
  spotify,
  appleMusic,
  bandcamp,
  type,
  isAuthor,
}) => {
  return (
    <Container backgroundColor={bannerBackgroundColor}>
      {type === 'person' && (
        <ArtistWrap isAuthor={isAuthor}>
          <ArtistName>
            <h1>{title}</h1>
            {spotify && (
              <StreamingList>
                <StreamingItem>
                  <a
                    className='socialMobileItem'
                    href={spotify}
                    target='_blank'
                    rel='noreferrer'
                    role='button'
                    aria-label='Mute volume'
                  >
                    <FaSpotify />
                  </a>
                </StreamingItem>
                <StreamingItem>
                  <a
                    className='socialMobileItem'
                    href={appleMusic}
                    target='_blank'
                    rel='noreferrer'
                    role='button'
                    aria-label='Mute volume'
                  >
                    <FaApple />
                  </a>
                </StreamingItem>
              </StreamingList>
            )}
          </ArtistName>
          <Info>
            <p>{bio}</p>
          </Info>
        </ArtistWrap>
      )}
      {(type === 'album' || type === 'guide') && (
        <AlbumWrap>
          <TextWrap color={bannerTextColor}>
            <MobileLabelInfo>
              {`${label ? label.englishName : 'Self-released'} • `}{' '}
              {date && date}
            </MobileLabelInfo>
            <div style={{ display: 'flex' }}>
              {artists && (
                <Link to={`/artist/${artists[0].slug}`}>
                  <h2>{artists[0].englishName}</h2>
                </Link>
              )}
              {foreginCollaborator && (
                <h2 style={{ marginLeft: '1rem' }}>& {foreginCollaborator}</h2>
              )}
            </div>

            <h1>{title}</h1>
            <Info>{bio && <p>{bio}</p>}</Info>
          </TextWrap>
          <ImgWrap type={type}>
            {image && <img src={image.url} alt='' />}
          </ImgWrap>
        </AlbumWrap>
      )}
    </Container>
  );
};

export default Banner;
