import React from 'react';

import GuideCard from './Card';
import { GuidesFlexWrap } from './styled';

const Guides = ({ edges, isPreview }) => {
  return (
    <GuidesFlexWrap>
      {edges.map((edge, pos) => {
        return <GuideCard {...edge} key={pos} isPreview={isPreview} />;
      })}
    </GuidesFlexWrap>
  );
};

export default Guides;
