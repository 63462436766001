export const LightScheme = {
  hamburger: '#FFFFFF',
  title: '#FFFFFF',
  logo: 'white',
  border: '2px solid #FFFFFF',
};

export const DarkScheme = {
  hamburger: '#FFFFFF',
  title: '#FFFFFF',
  logo: 'white',
  border: '2px solid #FFFFFF',
  background: '#000000',
};

export const OriginalScheme = {
  hamburger: '#000000',
  title: '#000000',
  logo: 'red',
  border: '2px solid #000000',
};

export const AlbumLightScheme = {
  hamburger: '#FFFFFF',
  title: '#FFFFFF',
  logo: 'white',
  border: '2px solid #000000',
};

export const AlbumDarkScheme = {
  hamburger: '#FFFFFF',
  title: '#FFFFFF',
  logo: 'white',
  border: '2px solid #FFFFFF',
  background: '#000000',
};
