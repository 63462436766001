import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { AlbumContainer, ImgWrap, Details } from './styled';

const AlbumCard = (props) => {
  let album;
  if (props.node) {
    album = props.node;
  } else {
    album = props;
  }

  const { englishTitle, artists, artwork, slug, releaseDate } = album;

  return (
    <AlbumContainer>
      <Link to={`/reccs/${slug}`}>
        <ImgWrap>
          <img src={artwork.url} alt='' className='image' />
        </ImgWrap>
      </Link>

      <Details>
        {artists && <p>{artists[0].englishName}</p>}
        <p>{englishTitle}</p>
        <p>{releaseDate}</p>
      </Details>
    </AlbumContainer>
  );
};

export default AlbumCard;
