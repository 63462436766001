import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  margin-bottom: 150px;
  background-color: ${(props) => props.backgroundColor || '#83ff00'};

  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    height: 280px;
    margin-bottom: 100px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    background-color: transparent;
    height: auto;
    margin: 20px 0 60px;
  }
`;

export const AlbumWrap = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  max-width: 920px;
  width: 90%;
  margin: 0 auto;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: translateY(80px);
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: relative;
    flex-flow: column;
    left: revert;
    right: revert;
    bottom: revert;
    transform: none;
  }
`;

export const ArtistWrap = styled.div`
  position: absolute;
  max-width: 900px;
  width: 90%;
  transform: translateY(140px);
  padding-left: 45px;
  h1 {
    font-size: 6rem;
    margin-bottom: 0;
  }
  p {
    width: 60%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    bottom: 20px;
    transform: translateY(0);
    p {
      width: 80%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: relative;
    bottom: initial;
    padding-left: 0;
    margin: 60px auto 0;
    text-align: center;
    h1 {
      font-size: 4rem;
    }
    p {
      width: 80%;
      transform: ${(props) =>
        props.isAuthor ? 'translateY(0)' : 'translateY(-40px)'};
    }
  }
`;

export const ArtistName = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: block;
  }
`;

export const StreamingList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
  transform: ${(props) =>
    props.isAlbums ? 'translate(18px, 8px)' : 'translate(36px, 46px)'};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    transform: ${(props) =>
      props.isAlbums ? 'translate(0)' : 'translate(0, 110px)'};
    width: 40%;
    margin: 0 auto;
    text-align: center;
  }
`;

export const StreamingItem = styled.li`
  margin-right: 18px;
  height: 50px;
  padding: 12px 13px 9px 13px;
  color: black;
  font-size: 24px;
  text-decoration: none;
  border: 1px solid black;
  border: ${(props) =>
    props.color ? `1px solid ${props.color}` : '1px solid black'};
  border-radius: 50%;
  &:last-child {
    margin-right: 0;
  }
`;

export const TextWrap = styled.div`
  max-width: 700px;
  width: 60%;
  padding: 10px 0 0 30px;
  color: ${(props) => props.color || 'white'};
  a {
    color: ${(props) => props.color || 'white'};
  }
  h1 {
    margin: 0;
    font-size: 3.8rem;
  }
  h2 {
    font-size: 2.4rem;
    margin: 0 0 10px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 2rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    order: 2;
    width: 100%;
    padding: 0;
    color: black;
    a {
      color: black;
    }
  }
`;

export const ImgWrap = styled.div`
  width: ${(props) => (props.type === 'guide' ? '500px' : '270px')};
  height: 270px;
  img {
    border-radius: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    order: 1;
    height: auto;
    width: 100%;
    margin: 30px 0;
    img {
      height: 100%;
      width: 100%;
    }
  }
`;

export const Info = styled.div`
  margin-top: 8px;
  font-size: 14px;
  p {
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    p {
      color: black !important;
      border-color: black !important;
      margin: 0 auto;
    }
  }
`;

export const Genre = styled.p`
  margin-top: 10px !important;
  font-size: 12px;
  color: ${(props) => props.color || 'white'};
  letter-spacing: 1px;
  border: ${(props) =>
    `1px solid ${props.color} !important` || '1px solid white !important'};
  border-radius: 60px;
  background-color: transparent;
  display: inline-block;
  padding: 16px 14px 15px;
  margin-right: 10px;
  line-height: 1.25;
  border: 0;
  text-align: center;
`;

export const MobileLabelInfo = styled.p`
  display: none;
  font-size: 1.2rem;
  text-align: center;
  transform: translateY(-30px);
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: block;
  }
`;
