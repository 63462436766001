import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(153, 153, 153);
  background-color: black;
`;

export const PageTitle = styled.div`
  color: white;
  text-align: center;
  margin-bottom: 170px;
  padding-top: ${(props) => (props.isHome ? '100px' : '80px')};
  h1 {
    font-size: 130px;
    font-weight: normal;
    margin-bottom: 20px;
    margin-top: 0;
  }
  p {
    font-size: 20px;
    margin: 0 auto;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    width: 90%;
    margin: 30px auto 50px;
    h1 {
      font-size: 40px;
      margin-bottom: 10px;
    }
    p {
      width: auto;
      font-size: 16px;
    }
  }
`;

export const LatestContainer = styled.div`
  margin: 0 auto;
  position: relative;
  width: 700px;
  height: 520px;
  margin: 0 auto 250px;
  text-align: center;
  h2 {
    z-index: 100;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Elevon';
    text-transform: uppercase;
    font-size: 30px;
    color: white;
    span {
      display: none;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    width: 90%;
    height: auto;
    margin: 0 auto 15vw;
    h2 {
      position: relative;
      top: revert;
      left: revert;
      margin: 20px 0;
      transform: none;
      font-size: 20px;
      text-align: left;
      span {
        display: inline-block;
        color: #9efe9e;
        margin-right: 1px;
      }
    }
  }
`;

export const LatestImg = styled.div`
  position: relative;
  z-index: 100;
  img {
    z-index: 10;
    margin: 0 auto;
    height: 520px;
    width: 380px;
    object-fit: cover;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    img {
      width: 100%;
      height: 350px;
    }
  }
`;

export const Hashtag = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.3);
  font-size: 300px;
  margin: 0;
  padding: 0;
  transform: translate(85px, -245px);
  p {
    font-family: 'Elevons';
    padding: 0;
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: none;
  }
`;

export const Id = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.3);
  font-size: 300px;
  transform: translate(105px, 260px);
  p {
    padding: 0;
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: none;
  }
`;

export const ScrollContainer = styled.div`
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
  margin-left: 16.6% !important;
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    width: 90%;
    margin: 0 auto !important;
  }
`;

export const Card = styled.div`
  padding: 0 15px;
  margin-bottom: 15vw;
  margin-right: 15vw;
  img {
    height: 350px;
    width: 250px;
    object-fit: cover;
  }
  h2 {
    width: 250px;
    font-family: 'Elevon';
    text-transform: uppercase;
    font-size: 20px;
    color: white;
    margin: 20px 0;
    span {
      color: #9efe9e;
      margin-right: 1px;
    }
  }
  &:nth-of-type(2n) {
    transform: translateY(5vw);
  }

  &:nth-of-type(4n -1) {
    margin-left: 12.5vw;
    margin-right: 0;
  }

  &:nth-of-type(4n) {
    margin-left: 12.5vw;
    margin-right: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    margin-right: 0;
    padding: 0;
    width: 100%;
    h2 {
      width: 100%;
    }
    img {
      width: 100%;
    }
    &:nth-of-type(2n) {
      transform: none;
    }

    &:nth-of-type(4n -1) {
      margin-left: 0;
      margin-right: 0;
    }

    &:nth-of-type(4n) {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;
