import styled from 'styled-components';

export const Container = styled.button`
  font-size: 12px;
  color: ${(props) => props.color || 'black'};
  letter-spacing: 1px;
  border: ${(props) =>
    `1px solid ${props.color} !important` || '1px solid black !important'};
  border-radius: 20px;
  background-color: transparent;
  display: inline-block;
  padding: 10px 14px;
  margin-right: 10px;
  line-height: 1.25;
  letter-spacing: 1px;
  border: 0;
  text-align: center;
  cursor: pointer;
  a {
    color: ${(props) => props.color || 'black'};
  }
  span {
    margin-left: 4px;
  }
  &:hover {
    color: ${(props) => 'black' || 'white'};
    background-color: ${(props) =>
      `${props.color} !important` || 'black !important'};
    border-color: ${(props) =>
      `${props.color} !important` || 'black !important'};
    &.active {
      color: white;
      border-color: #56b3c3 !important;
    }
  }
  &.active {
    color: ${(props) => 'black' || 'white'};
    background-color: #56b3c3 !important;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

export const InnerWrap = styled.div``;
