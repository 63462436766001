import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  z-index: 1000;
  height: 80px;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: black;
  border-top: 2px solid white;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

export const Item = styled.div`
  display: flex;
  margin: auto 0;
  padding-bottom: 8px;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
  background-color: black;
  border-right: 2px solid white;
  color: white;
  &:last-of-type {
    border-right: none;
  }
  &:nth-of-type(2) {
    border-right: none;
  }
  &:nth-of-type(3) {
    background-color: black;
    border-radius: 90px 90px 0 0;
    border: 1.4px solid white;
    height: 120px;
    width: 20%;
    transform: scale(1.2);
    img {
      transform: translateY(-20px);
      width: 76px;
    }
  }
`;

export const Wrap = styled.div`
  display: block;
  text-align: center;
  color: white;
  transform: translateY(-2px);
  p {
    line-height: 0;
    padding: 0;
  }
`;
