import React from 'react';

import AlbumCard from './Card';
import { AlbumFlexWrap } from './styled';

const Albums = ({ edges, preview }) => {
  const albums = preview ? edges.slice(0, 6) : edges;

  return (
    <AlbumFlexWrap>
      {albums.map((album, pos) => {
        return <AlbumCard {...album} key={pos} />;
      })}
    </AlbumFlexWrap>
  );
};

export default Albums;
