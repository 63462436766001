import React from 'react';
import { Link } from 'gatsby';

import { GuideContainer, GuideWrap, Image, Title } from './styled';

const GuideCard = ({ node, isPreview }) => {
  const { slug, coverImage, title } = node;

  const truncateTitle = (title) => {
    if (title.includes('Japanese')) {
      return title.replace('Japanese ', '');
    } else {
      return title;
    }
  };

  return (
    <GuideContainer isPreview={isPreview}>
      <Link to={`/guides/a-brief-guide-to-${slug}`}>
        <GuideWrap>
          <Image
            style={{
              backgroundImage: `radial-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)), url(${coverImage.url})`,
            }}
          />
          <Title>
            <p>{truncateTitle(title)}</p>
          </Title>
        </GuideWrap>
      </Link>
    </GuideContainer>
  );
};

export default GuideCard;
