import React, { useRef, useEffect } from 'react';

// import useWindowSize from './../../hooks/useWindowSize';

import LatestSelector from './Latest.js';
import SelectorCard from './Card.js';
import { Container, PageTitle, ScrollContainer } from './styled.js';

const Selectors = ({ edges, isHome }) => {
  const latest = edges[0];
  const selectors = edges.slice(1);

  // const size = useWindowSize();

  const app = useRef();
  const scrollContainer = useRef();

  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  };

  useEffect(() => {
    requestAnimationFrame(() => skewScrolling());
  }, []);

  // useEffect(() => {
  //   setBodyHeight();
  // }, [size.height]);

  const setBodyHeight = () => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`;
  };

  const skewScrolling = () => {
    data.current = window.scrollY;
    data.previous += (data.current - data.previous) * data.ease;
    data.rounded = Math.round(data.previous * 100) / 100;

    const difference = data.current - data.rounded;
    const acceleration = difference / 1366;
    const velocity = +acceleration;
    const skew = velocity * 20.5;

    scrollContainer.current.style.transform = `skewY(${skew}deg)`;

    requestAnimationFrame(() => skewScrolling());
  };

  if (!Array.isArray(edges)) return null;

  if (edges.length < 1) {
    return (
      <Container>
        <p>Content is on the way - stay tuned!</p>
      </Container>
    );
  }

  return (
    <Container ref={app}>
      <PageTitle isHome={isHome}>
        <h1>Selector</h1>
        <p>Curated playlists from leading names in Japanese music</p>
      </PageTitle>
      {!isHome && <LatestSelector {...latest} />}
      <ScrollContainer ref={scrollContainer}>
        {selectors.map((selector, pos) => {
          return <SelectorCard key={pos} {...selector} />;
        })}
      </ScrollContainer>
    </Container>
  );
};

export default Selectors;
