import React from 'react';
import { Link } from 'gatsby';

import LogoRed from 'assets/logo_icon_red.svg';
import LogoWhite from 'assets/logo_icon_white.png';
import LogoBlack from 'assets/logo_icon_black.png';
import HamburgerMenu from 'components/HamburgerNav/HamburgerMenu';
import { LightScheme } from 'helpers/navColors.js';

import { Container, BackgroundColor, Hamburger } from './styled';

const Navigation = ({ colorScheme }) => {
  const hamburger = colorScheme ? colorScheme.hamburger : LightScheme.hamburger;
  const title = colorScheme ? colorScheme.title : LightScheme.title;
  const border = colorScheme ? colorScheme.border : LightScheme.border;
  const background =
    colorScheme && colorScheme.background
      ? { backgroundColor: colorScheme.background }
      : { display: 'none' };

  let logo = LogoWhite;
  switch (colorScheme?.logo) {
    case 'red':
      logo = LogoRed;
      break;
    case 'black':
      logo = LogoBlack;
  }

  return (
    <Container style={{ borderRight: border }}>
      <BackgroundColor style={background} />
      <Hamburger>
        <HamburgerMenu />
      </Hamburger>

      <h1 style={{ color: title }}>
        <Link to='/'>The Glow</Link>
      </h1>

      <Link to='/'>
        <img src={logo} alt='Logo' style={{ width: '45px' }} />
      </Link>
    </Container>
  );
};

export default Navigation;
