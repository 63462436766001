import styled from 'styled-components';
import { motion } from 'framer-motion';

export const NavbarContainer = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 1.5em;
  z-index: 1000;
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: block;
  }
`;

export const NavbarLogo = styled.div`
  width: 13rem;
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: 4px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  img {
    display: block;
    width: 100%;
    margin: 0;
  }
`;

export const HamburgerMenuContainer = styled.div`
  display: flex;
`;

export const MenuContainer = styled(motion.div)`
  width: calc(100vw + 6em);
  max-width: calc(100vw + 6em);
  height: 100%;
  background-color: black;
  z-index: 90;
  position: fixed;
  top: 0;
  left: 0;
  user-select: none;
  padding: 1em 2.5em;
`;

export const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
`;

export const SocialButton = styled(motion.button)`
  color: black;
  font-size: 2.5rem;
  transition: all 250ms ease-in-out;
  display: flex;
  cursor: pointer;
  padding: 5px 0.5em;
  &:focus {
    outline: none;
  }
  a {
    color: black;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`;

export const Button = styled.div`
  z-index: 99;
  cursor: pointer;
  position: absolute;
  right: 0;
  margin: 15px 28px 0 0;
`;

export const NavMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.tabletLan}) {
    display: block;
  }
`;

export const NavList = styled.ul`
  transform: translate(60px, 55px);
  width: 63%;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    transform: translate(0, 55px);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletLan}) {
    width: 100%;
    transform: translate(-114px, 55px);
  }
`;

export const NavLink = styled(motion.li)`
  font-family: 'Elevon';
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 2.5% 0;
  a {
    display: inline;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    color: white;
    font-size: 12rem;
  }
  a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 8px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    a {
      font-size: 8rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletLan}) {
    a {
      font-size: 7rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    a {
      font-size: 6rem;
    }
  }
`;

export const NavDetails = styled.div`
  position: relative;
  width: 30%;
  transform: translateY(20px);
  div {
    position: absolute;
    bottom: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletLan}) {
    width: 100%;
    transform: translate(24px, 140px);
  }
`;
