import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1000;
  margin-bottom: 140px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 90%;
    margin: 0 auto 20px;
  }
`;

export const Subtitle = styled.div`
  width: 775px;
  margin: 0 auto 50px;
  p {
    font-weight: bolder;
    font-size: 18px;
    font-weight: 600;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const Body = styled.div`
  width: 560px;
  margin: 0 auto;
  a {
    color: #7454a1;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
    font-weight: bolder;
    &:hover {
      text-decoration: underline;
      text-decoration-color: #7454a1;
    }
  }
  p {
    font-size: 1.6rem;
    line-height: 2;
  }
  h5 {
    font-size: 2rem;
  }
  h4 {
    transform: translateY(50px);
  }
  h3 {
    margin-top: 10px;
  }
  img {
    width: 100%;
    margin: -30px 0 20px;
  }
  iframe {
    width: 100%;
    margin-bottom: 20px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    iframe {
      height: 200px;
    }
  }
`;

export const ArtistTags = styled.div`
  width: 560px;
  margin: 40px auto 60px;
  padding-top: 40px;
  text-transform: uppercase;
  color: #c0c0c0;
  font-size: 1.5rem;
  font-weight: bold;
  border-top: 2px solid black;
  a {
    color: black;
    text-decoration: none;
    &:hover {
      color: #7454a1;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding-top: 32px;
    margin-bottom: 0;
    p {
      margin: 2px 0;
    }
  }
`;
