import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { FaRegEnvelope, FaInstagram } from 'react-icons/fa';

import MenuToggle from './MenuToggle';
import NavMenu from './NavMenu';

import {
  HamburgerMenuContainer,
  MenuContainer,
  BottomContainer,
  SocialContainer,
  ContentContainer,
} from './styled';

const menuVariants = {
  open: {
    transform: 'translateX(-3%)',
  },
  closed: {
    transform: 'translateX(-103%)',
  },
};

const menuTransition = {
  type: 'spring',
  duration: 1,
  stiffness: 33,
  delay: 0.1,
};

const commonVariants = {
  show: {
    transform: 'translateX(0em)',
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 0.01,
    },
  },
  hide: {
    transform: 'translateX(5em)',
    opacity: 0,
  },
};

const commonTransition = { type: 'spring', duration: 0.05 };

const HamburgerMenu = (props) => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    setActiveMenu(false);
  }, [location]);

  const [isOpen, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  return (
    <HamburgerMenuContainer>
      <MenuToggle toggle={toggleMenu} isOpen={isOpen} />
      <MenuContainer
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={menuVariants}
        transition={menuTransition}
      >
        <ContentContainer>
          <NavMenu isOpen={isOpen} />
        </ContentContainer>
      </MenuContainer>
    </HamburgerMenuContainer>
  );
};

export default HamburgerMenu;
