import React from 'react';
import { Link } from 'gatsby';

import { formatAuthor } from 'helpers/formatLists';
import { truncateString } from 'helpers/truncateString';

import { FeatureContainer, Category, ImgWrap, TextWrap, Info } from './styled';

const Feature = ({ node }) => {
  const {
    title,
    category,
    subcategory,
    slug,
    publishedDate,
    authors,
    artists,
    coverImage,
  } = node;

  const formattedAuthor = formatAuthor(authors);
  const formattedTitle =
    category.name === 'Review'
      ? `Review: ${artists[0].englishName} - ${title}`
      : title;

  const truncatedAuthor = truncateString(formattedAuthor, 23);
  const truncatedTitle = truncateString(formattedTitle, 42);

  return (
    <FeatureContainer>
      <Category>
        <p>{category.name}</p>
      </Category>
      <ImgWrap>
        <Link
          to={`/${
            (subcategory && subcategory.name) == 'Selector'
              ? 'selector'
              : 'features'
          }/${slug}`}
        >
          <img src={coverImage.url} alt='' />
        </Link>
      </ImgWrap>
      <TextWrap>
        <Info>
          <p className='type'>
            <strong>{truncatedAuthor}</strong> <span>|</span>
            {publishedDate}
          </p>
        </Info>
        <Link to={`/features/${slug}`}>
          <h3>{truncatedTitle}</h3>
        </Link>
      </TextWrap>
    </FeatureContainer>
  );
};

export default Feature;
