import React from 'react';
import { Link } from 'gatsby';

import { Card } from './styled';

const SelectorCard = ({ node }) => {
  const { artists, coverImage, selectorID, slug } = node;

  return (
    <Card>
      <Link to={`/selector/${slug}`}>
        {coverImage && <img src={coverImage.url} alt='' />}
        <h2>
          <span>#{selectorID}</span>
          {artists[0].englishName}
        </h2>
      </Link>
    </Card>
  );
};

export default SelectorCard;
