import React from 'react';

import FeatureCard from './Card.js';
import { FeatureFlexWrap } from './styled';

const Features = ({ edges }) => {
  return (
    <FeatureFlexWrap>
      {edges.map((edge, pos) => {
        return <FeatureCard {...edge} key={pos} />;
      })}
    </FeatureFlexWrap>
  );
};

export default Features;
