import React from 'react';
import { Link } from 'gatsby';
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaSpotify,
  FaApple,
} from 'react-icons/fa';

import { Socials } from 'components/Footer/styled';

import { NavMenuContainer, NavList, NavLink, NavDetails } from './styled';

const variants = {
  show: {
    transform: 'translateX(10em)',
    opacity: 1,
  },
  hide: {
    transform: 'translateX(-5em)',
    opacity: 0,
  },
};

const NavMenu = ({ isOpen }) => {
  return (
    <NavMenuContainer>
      <NavList>
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.4, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.1, duration: 0.05 },
            },
          }}
        >
          <Link to='/new-reccs'>New Reccs</Link>
        </NavLink>
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.5, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.15, duration: 0.05 },
            },
          }}
        >
          <Link to='/explore'>Explore</Link>
        </NavLink>
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.3, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.05, duration: 0.05 },
            },
          }}
        >
          <Link to='/selector'>Selector</Link>
        </NavLink>
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.6, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.2, duration: 0.05 },
            },
          }}
        >
          <Link to='/features'>Features</Link>
        </NavLink>
      </NavList>
      <NavDetails>
        <Socials>
          <ul>
            <li>
              <a
                href='https://www.instagram.com/theglow.jp/'
                target='_blank'
                rel='noreferrer'
                role='button'
                aria-label='Mute volume'
              >
                <FaInstagram />
              </a>
            </li>
            <li>
              <a
                href='https://www.facebook.com/theglow.jp'
                target='_blank'
                rel='noreferrer'
                role='button'
                aria-label='Mute volume'
              >
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a
                href='https://twitter.com/theglow_jp/'
                target='_blank'
                rel='noreferrer'
                role='button'
                aria-label='Mute volume'
              >
                <FaTwitter />
              </a>
            </li>
            <li>
              <a
                className='socialMobileItem'
                href='https://open.spotify.com/user/ji21784pk94jfyv4u9w01xkhe?si=ZOykmTClStyjbTpG9XmPGg'
                target='_blank'
                rel='noreferrer'
                role='button'
                aria-label='Mute volume'
              >
                <FaSpotify />
              </a>
            </li>
            <li>
              <a
                className='socialMobileItem'
                href='https://itunes.apple.com/profile/theglow_jp'
                target='_blank'
                rel='noreferrer'
                role='button'
                aria-label='Mute volume'
              >
                <FaApple />
              </a>
            </li>
          </ul>
        </Socials>
      </NavDetails>
    </NavMenuContainer>
  );
};

export default NavMenu;
