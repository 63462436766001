import React from 'react';
import { Link } from 'gatsby';

import { Container, List, ListItem } from './styled';

const Header = ({ color }) => {
  return (
    <Container borderColor={color}>
      <List>
        <ListItem textColor={color}>
          <Link className='navItem' to='/new-reccs'>
            New Reccs
          </Link>
        </ListItem>
        <ListItem textColor={color}>
          <Link className='navItem' to='/explore'>
            Explore
          </Link>
        </ListItem>
        <ListItem textColor={color}>
          <Link className='navItem' to='/selector'>
            Selector
          </Link>
        </ListItem>
        <ListItem textColor={color}>
          <Link className='navItem' to='/features'>
            Features
          </Link>
        </ListItem>
      </List>
    </Container>
  );
};

export default Header;
