import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import {
  RiSearchEyeFill,
  RiPlayListLine,
  RiPlayListFill,
} from 'react-icons/ri';
import { BsDisc, BsDiscFill } from 'react-icons/bs';
import { BiSearchAlt } from 'react-icons/bi';
import { IoDocumentTextSharp, IoDocumentTextOutline } from 'react-icons/io5';

import LogoWhite from 'assets/logo_icon_white.png';

import { Container, Item, Wrap } from './styled';

const MobileNav = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(false);

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  return (
    <Container>
      <Item>
        <Link to='/new-reccs'>
          <Wrap>
            {activeTab === '/new-reccs/' || activeTab === '/new-reccs' ? (
              <BsDiscFill size='20' color='white' />
            ) : (
              <BsDisc size='20' color='white' />
            )}
            <p>New Reccs</p>
          </Wrap>
        </Link>
      </Item>
      <Item>
        <Link to='/explore'>
          <Wrap>
            {activeTab === '/explore/' ? (
              <RiSearchEyeFill size='20' color='white' />
            ) : (
              <BiSearchAlt size='20' color='white' />
            )}
            <p>Explore</p>
          </Wrap>
        </Link>
      </Item>
      <Item>
        <Link to='/'>
          <Wrap>
            <img src={LogoWhite} alt='home' />
          </Wrap>
        </Link>
      </Item>
      <Item>
        <Link to='/selector'>
          <Wrap>
            {activeTab === '/selector/' ? (
              <RiPlayListFill size='20' color='white' />
            ) : (
              <RiPlayListLine size='20' color='white' />
            )}
            <p>Selector</p>
          </Wrap>
        </Link>
      </Item>
      <Item>
        <Link to='/features'>
          <Wrap>
            {activeTab === '/features/' ? (
              <IoDocumentTextSharp size='20' color='white' />
            ) : (
              <IoDocumentTextOutline size='20' color='white' />
            )}
            <p>Features</p>
          </Wrap>
        </Link>
      </Item>
    </Container>
  );
};

export default MobileNav;
