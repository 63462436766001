import React from 'react';
import { Link } from 'gatsby';

import Button from 'components/common/Button';
import { HeaderWrap } from 'components/common/Containers/styled';
import { Albums, Features, SelectorsLatest } from 'components';

import { Container } from './styled';

const Latest = ({ data, sectionTitle, link }) => {
  return (
    <Container>
      <HeaderWrap>
        <h3>Latest {sectionTitle}</h3>
        <Link to={link}>
          <Button>View All</Button>
        </Link>
      </HeaderWrap>
      {sectionTitle === 'reccs' && <Albums {...data} />}
      {sectionTitle === 'features' && <Features {...data} />}
      {sectionTitle === 'selectors' && <SelectorsLatest {...data} />}
    </Container>
  );
};

export default Latest;
