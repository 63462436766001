import React from 'react';

import { Container, InnerWrap } from './styled';

const Button = ({ children, color }) => {
  return (
    <Container color={color}>
      <InnerWrap color={color}>{children}</InnerWrap>
    </Container>
  );
};

export default Button;
